<template>
  <li
    class="module-item"
    :class="selected ? 'active' : ''"
    @click="onModuleSelect(values)"
  >
    <div class="module-label">{{ values.name }}</div>
    <div class="module-badge" v-if="type === CREATE_TYPE.TRADITION">
      {{ values.number }}{{ $t("section") }}
    </div>
    <div class="module-required" v-if="values.required">
      {{ $t("echeck.detail.must") }}
    </div>
    <div class="module-times" v-if="type === CREATE_TYPE.QUICK">
      {{ $t("echeck.detail.last") }}
      <span>{{ values.remaining || 0 }}</span>
      {{ $t("echeck.detail.times") }}
    </div>
    <div class="module-checkbox" v-if="type === CREATE_TYPE.QUICK">
      <i class="iconfont icon-check-select" v-if="selected"></i>
      <i class="iconfont icon-check-default" v-else></i>
    </div>
  </li>
</template>

<script>
import { CREATE_TYPE } from "@/pages/home/order/config";

export default {
  name: "TemplateModule",
  props: {
    values: {
      type: Object,
      required: true
    },
    selected: Boolean,
    type: String
  },
  data() {
    return {
      CREATE_TYPE
    };
  },
  methods: {
    onModuleSelect(module) {
      this.$emit("module-select", module);
    }
  }
};
</script>

<style lang="less" scoped>
.module-item {
  width: 306px;
  height: 105px;
  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(15, 113, 222, 0.06);
  border-radius: 8px;
  border: 1px solid #ccd8f0;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 306px;

  &.active {
    border-color: @primary-color;
  }
}
.module-label {
  font-size: 16px;
  font-weight: 400;
  color: #293b58;
}
.module-badge {
  height: 20px;
  line-height: 18px;
  background: #fff3e3;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #ffbc36;
  font-size: 14px;
  font-weight: 400;
  color: #ffbc36;
  padding: 0 8px;
}
.module-required {
  width: 45px;
  height: 24px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: @primary-color;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  left: -1px;
  top: -1px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
}
.module-times {
  font-size: 14px;
  font-weight: 400;
  color: #757e8c;
  margin-top: 5px;

  > span {
    color: @primary-color;
  }
}
.module-checkbox {
  position: absolute;
  z-index: 1;
  top: 11px;
  right: 21px;
}
</style>
