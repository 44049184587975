<template>
  <div class="background-order-template">
    <div class="template-list-wrapper">
      <div class="template-list-page prev">
        <img
          src="@/assets/images/order/leftBtn02.png"
          alt=""
          v-if="prevPageDisabled"
          class="disabled"
        />
        <img
          src="@/assets/images/order/leftBtn01.png"
          alt=""
          @click="onPage('prev')"
          v-else
        />
      </div>
      <div class="template-list-container" ref="templateListRef">
        <ul class="template-list">
          <li
            class="template-item"
            :class="selected.id === item.id ? 'active' : ''"
            v-for="item in templateList"
            :key="item.id"
            @click="onTemplateSelect(item)"
          >
            <div class="template-checkbox">
              <a-icon type="check" />
            </div>
            <img
              class="template-icon"
              src="@/assets/images/order/templateIco01.png"
              alt=""
              v-if="selected.id === item.id"
            />
            <img
              class="template-icon"
              src="@/assets/images/order/templateIco02.png"
              alt=""
              v-else
            />
            <div class="template-label">{{ item.name }}</div>
          </li>
        </ul>
      </div>
      <div class="template-list-page next">
        <img
          src="@/assets/images/order/rightBtn02.png"
          alt=""
          v-if="nextPageDisabled"
          class="disabled"
        />
        <img
          src="@/assets/images/order/rightBtn01.png"
          alt=""
          @click="onPage('next')"
          v-else
        />
      </div>
    </div>
    <ul class="template-module-list">
      <TemplateModule
        :selected="selected.moduleIds.includes(it.id)"
        :style="{
          cursor: selected.type === CREATE_TYPE.QUICK ? 'pointer' : 'default'
        }"
        :type="selected.type"
        :values="it"
        v-for="it in selected.module"
        :key="it.id"
        @module-select="onModuleSelect(it)"
      />
    </ul>
    <div class="template-module-qr" v-if="downloadQRCodeVisible">
      <a-button
        type="link"
        class="qr-code-link"
        @click="onQRCode"
        :disabled="qrCodeLoading"
      >
        {{ $t("order.detail.downloadQRCode") }}
      </a-button>
    </div>
    <div class="template-tips">
      <h5 class="tips-title">
        <a-icon
          type="exclamation-circle"
          theme="filled"
          class="ec-color-warning"
        />
        <span>{{ $t("backCheck.tips") }}</span>
      </h5>
      <ol class="tips-ol">
        <li>
          {{ $t("order.detail.templateTips1") }}
        </li>
        <li>
          {{ $t("order.detail.templateTips2") }}
        </li>
      </ol>
    </div>
    <div class="background-order-foot">
      <a-button class="foot-btn" @click="onPrev">
        {{ $t("button.previous") }}
      </a-button>
      <a-button class="foot-btn" type="primary" @click="onNext">
        {{ $t("button.next") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState } from "vuex";
import { BACK_ORDER_PROGRESS } from "@/pages/workspace/backgroundCheck/order/config";
import { showMsg } from "@/utils";
import { FETCH_CODE } from "@/config";
import { CREATE_TYPE } from "@/pages/home/order/config";
import TemplateModule from "@/pages/workspace/backgroundCheck/order/components/Module.vue";
import { quickModuleQRCode, templateQRCode } from "@/pages/home/order/api";
import QRCode from "qrcode";

export default {
  name: "BackOrderAddTemplate",
  components: { TemplateModule },
  props: {
    type: String
  },
  data() {
    return {
      scrollLeft: 0,
      scrollWidth: 0,
      CREATE_TYPE,
      qrCodeLoading: false
    };
  },
  computed: {
    ...mapState({
      selected: state => state.back.selectedTemplate,
      templateList: state => state.back.templateList,
      lineConfig: state => state.back.lineConfig
    }),
    prevPageDisabled() {
      return this.scrollLeft <= 0;
    },
    nextPageDisabled() {
      return this.scrollLeft >= this.scrollWidth;
    },
    downloadQRCodeVisible() {
      // 未选择模板不显示
      if (!this.selected.id) return false;
      // 配置不显示不显示
      if (!this.lineConfig.enableCandidateQROrder) return false;
      // 选择易查宝未选择背调模块不显示
      if (
        this.selected.type === CREATE_TYPE.QUICK &&
        !this.selected.moduleIds.length
      ) {
        return false;
      }
      return true;
    }
  },
  watch: {
    templateList(newList, oldList) {
      if (newList.length !== oldList.length) {
        this.$nextTick(() => {
          this.onListResize();
        });
      }
    }
  },
  methods: {
    onTemplateSelect(template) {
      if (!template.moduleIds) {
        template.moduleIds = [];
      }
      if (template.type === CREATE_TYPE.QUICK) {
        template.module.forEach(it => {
          if (it.required && template.moduleIds.indexOf(it.id) < 0) {
            template.moduleIds.push(it.id);
          }
        });
      }
      // 部署后偶现的一个报错，会导致程序死机
      // vue.runtime.esm.js:1897 DOMException: Failed to execute 'insertBefore' on 'Node':
      // The node before which the new node is to be inserted is not a child of this node.
      // at Object.mi [as insertBefore] (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:42416)
      //   at S (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:46877)
      // at T (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:47595)
      // at S (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:46515)
      // at T (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:47595)
      // at o.__patch__ (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:48735)
      // at Vn.e._update (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:27070)
      // at o.r (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:27893)
      // at rr.get (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:30788)
      // at rr.run (https://test.ehr.hrmany.com/js/chunk-vendors.70d09873.js:63:31521)
      try {
        this.$store.commit("back/setSelectedTemplate", cloneDeep(template));
      } catch (err) {
        console.log(err);
      }
    },
    onModuleSelect(module) {
      const selected = cloneDeep(this.selected);
      if (selected.type === CREATE_TYPE.TRADITION) {
        return;
      }
      const moduleIndex = selected.moduleIds.indexOf(module.id);
      if (module.required && moduleIndex > -1) {
        return;
      }
      // 取消选择
      if (moduleIndex > -1) {
        selected.moduleIds.splice(moduleIndex, 1);
      }
      // 选择
      else {
        selected.moduleIds.push(module.id);
      }
      this.$store.commit("back/setSelectedTemplate", cloneDeep(selected));
    },
    onPage(direction) {
      const { scrollLeft, scrollWidth, width, target } = this.getListScroll();
      // 每次尝试滚动可视窗口的1/2
      // 即width/2
      // 向后
      if (direction === "next") {
        let _scrollLeft = scrollLeft + width / 2;
        if (_scrollLeft > scrollWidth) {
          _scrollLeft = scrollWidth;
        }
        this.scrollLeft = _scrollLeft;
        target.scrollTo({ left: _scrollLeft, behavior: "smooth" });
        return;
      }
      // 向前
      let _scrollLeft = scrollLeft - width / 2;
      if (_scrollLeft < 0) {
        _scrollLeft = 0;
      }
      this.scrollLeft = _scrollLeft;
      target.scrollTo({ left: _scrollLeft, behavior: "smooth" });
    },
    onListScroll() {
      const { scrollLeft } = this.getListScroll();
      this.scrollLeft = scrollLeft;
    },
    getListScroll() {
      const target = this.$refs.templateListRef;
      const styles = getComputedStyle(target);
      const width = parseFloat(styles.width) || 0;
      const scrollWidth = target.scrollWidth - width;
      const scrollLeft = target.scrollLeft;
      return {
        target,
        width,
        scrollWidth,
        scrollLeft
      };
    },
    onListResize() {
      const { scrollLeft, scrollWidth } = this.getListScroll();
      this.scrollLeft = scrollLeft;
      this.scrollWidth = scrollWidth;
    },
    onPrev() {
      // 2. 模板选择 -> 1. 上传方式
      this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[0].key);
      this.$router.replace({
        name: "BackOrderAdd"
      });
    },
    onNext() {
      // 判断是否选择了模板
      if (!this.selected.id) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: `${this.$t("select")}${this.$t("backCheck.template")}!`
        });
        return;
      }
      // 判断易查宝是否选择了模块
      if (
        this.selected.type === CREATE_TYPE.QUICK &&
        (!this.selected.moduleIds || !this.selected.moduleIds.length)
      ) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: `${this.$t("select")}${this.$t("leastOne")}${this.$t(
            "backCheck.module"
          )}!`
        });
        return;
      }
      // 2. 模板选择 -> 3. 信息完善
      this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[2].key);
      this.$router.replace({
        name: "BackOrderAddForm",
        params: {
          type: this.type
        },
        query: this.$route.query
      });
    },
    async onQRCode() {
      if (this.qrCodeLoading) return;
      this.qrCodeLoading = true;
      // 易查宝
      if (this.selected.type === CREATE_TYPE.QUICK) {
        const res = await quickModuleQRCode({
          ids: this.selected.moduleIds.join(","),
          companyId: this.$store.state.back.companyId
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.qrCodeLoading = false;
          return;
        }
        await this.downloadQRCode(res.data);
        this.qrCodeLoading = false;
        return;
      }
      // 传统背调
      if (this.selected.type === CREATE_TYPE.TRADITION) {
        const res = await templateQRCode({
          uuid: this.selected.uuid,
          companyId: this.$store.state.back.companyId
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.qrCodeLoading = false;
          return;
        }
        await this.downloadQRCode(res.data);
        this.qrCodeLoading = false;
        return;
      }
      showMsg({
        flag: 1,
        msg: "请先选择背调模板"
      });
    },
    downloadQRCode(data) {
      return new Promise(resolve => {
        QRCode.toDataURL(
          data.url,
          {
            errorCorrectionLevel: "H",
            width: 500
          },
          (err, url) => {
            if (err) {
              showMsg({
                flag: FETCH_CODE.ERROR.KEY,
                msg: "客户端转换二维码失败：" + err
              });
              resolve(false);
              return;
            }
            const DPR =
              window.devicePixelRatio ||
              window.webkitDevicePixelRatio ||
              window.mozDevicePixelRatio ||
              1;
            const canvas = document.createElement("canvas");
            canvas.width = 500;
            canvas.height = 570;
            canvas.style.width = `${500 / DPR}px`;
            canvas.style.height = `${570 / DPR}px`;
            const context = canvas.getContext("2d");
            // 全局背景色
            context.fillStyle = "#ffffff";
            context.fillRect(0, 0, canvas.width, canvas.height);
            // canvas绘制文字方法
            const drawFont = (ctx, { text, color, y, size, bold }) => {
              ctx.beginPath();
              ctx.font = `${bold ? "bold" : ""} ${size}px "Microsoft YaHei"`;
              ctx.fillStyle = color;
              ctx.textAlign = "center";
              ctx.fillText(text, 250, y);
              ctx.closePath();
            };
            // 加载二维码图片
            const img = new Image();
            img.src = url;
            img.onload = () => {
              // 将二维码绘制在canvas上
              context.drawImage(img, 0, 70);
              // 在canvas上添加 模板名称-企业名称
              drawFont(context, {
                text: data.templateName,
                color: "#000000",
                bold: true,
                size: 16 * DPR,
                y: 30 * DPR
              });
              drawFont(context, {
                text: data.companyName,
                color: "#666666",
                bold: false,
                size: 14 * DPR,
                y: 52 * DPR
              });
              // 下载最终二维码图片
              const a = document.createElement("a");
              a.href = canvas.toDataURL();
              a.download = `${data.templateName}-${data.companyName}.png`;
              const event = new MouseEvent("click");
              a.dispatchEvent(event);
              resolve(true);
            };
          }
        );
      });
    }
  },
  mounted() {
    this.$store.commit("back/setProgressActive", BACK_ORDER_PROGRESS[1].key);
    this.onListResize();
    window.addEventListener("resize", this.onListResize);
    this.$refs.templateListRef.addEventListener("scroll", this.onListScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onListResize);
    this.$refs.templateListRef.removeEventListener("scroll", this.onListScroll);
  }
};
</script>

<style lang="less" scoped>
.background-order-template {
  .template-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .template-list-page {
      width: 88px;
      flex-basis: 88px;
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;

      &:last-child {
        justify-content: flex-end;
      }

      img {
        width: 51px;
        height: 51px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
    .template-list-container {
      flex: 1;
      overflow-y: hidden;
      overflow-x: auto;
    }
    .template-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 24px;
      margin: 0;
    }
    .template-item {
      width: 180px;
      height: 180px;
      background: #ffffff;
      border: 1px solid #ccd8f0;
      text-align: center;
      border-radius: 8px;
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: 180px;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      padding: 34px 15px 0;
      cursor: pointer;

      &.active {
        border-color: @primary-color;

        .template-checkbox {
          background: @primary-color;
        }
        .template-label {
          color: @primary-color;
        }
      }
    }
    .template-checkbox {
      width: 28px;
      height: 28px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      background: #ccd8f0;
      color: #ffffff;
      text-align: center;
      line-height: 28px;
      font-size: 18px;
      position: absolute;
      z-index: 1;
      right: -1px;
      top: -1px;
    }
    .template-icon {
      width: 40px;
      height: 40px;
    }
    .template-label {
      font-size: 16px;
      font-weight: 400;
      color: #293b58;
    }
  }
  .template-module-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 45px;
  }
  .template-module-qr {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .template-tips {
    height: 129px;
    background: #fff5e6;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #ffbc36;
    font-size: 14px;
    font-weight: normal;
    color: #757e8c;
    line-height: 32px;
    padding: 18px 21px;
    margin-top: 18px;

    .tips-title {
      font-size: 18px;
      font-weight: 400;
      color: #293b58;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      margin-bottom: 0;

      .anticon-info-circle {
        color: #ffbc36;
        font-size: 22px;
      }
    }
    .tips-ol {
      margin-top: 7px;
      padding-left: 50px;
      list-style: decimal;

      li {
      }
    }
  }
}
</style>
